import gql from "graphql-tag";

export const BookingDateRequests = {
    Queries: {
        BookingDateRequests: gql`
        query bookingDateRequests($bookingTicketId: ID, $bookingTicketVoucherId: ID) {
            bookingDateRequests(bookingTicketId: $bookingTicketId, bookingTicketVoucherId: $bookingTicketVoucherId) {
                Id
                Guid
                Date
                BookingDateRequestStatusId
                BookingTicketId
                BookingTicketVoucherId
                CreatedAt
                UpdatedAt
                RefusalReason
                Inactive
                BookedByQuota
                ProductQuotaID
                CreatedBy
                UpdatedBy
            }
        }`,
    },
    Mutations: {},
};
