import gql from "graphql-tag";

export const BookingTickets = {
    Queries: {
        BookingTickets: gql`
        query bookingTickets(
            $hostIds: [ID]
            $voucherId: ID
            $bookingTicketStatusIds: [Int]
            $offset: Int
            $limit: Int
            $filter: String
        ) {
            bookingTickets(
                hostIds: $hostIds
                voucherId: $voucherId
                filter: $filter
                bookingTicketStatusIds: $bookingTicketStatusIds
                offset: $offset
                limit: $limit
            ) {
                Id
                Guid
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
                ThirdPartyBookingId
                AddressId
                BookingTicketTypeId
            }
        }`,
        BookingTicketsCount: gql`
        query bookingTicketsCount(
            $hostIds: [ID]
            $bookingTicketStatusIds: [Int]
            $filter: String
        ) {
            bookingTicketsCount(
                hostIds: $hostIds
                bookingTicketStatusIds: $bookingTicketStatusIds
                filter: $filter
            )
        }`,
        BookingTicket: gql`
        query bookingTicket($id: ID, $guid: String) {
            bookingTicket(id: $id, guid: $guid) {
                Id
                Guid
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
                ThirdPartyBookingId
                AddressId
                BookingTicketTypeId
            }
        }`,
        BookingTicketsByVoucherId: gql`
        query bookingTicketsByVoucherId($voucherId: ID) {
            bookingTicketsByVoucherId(voucherId: $voucherId) {
                Id
                Guid
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
                ThirdPartyBookingId
                AddressId
                BookingTicketTypeId
            }
        }`,
    },
    Mutations: {
        SaveBookingTicket: gql`
        mutation saveBookingTicket($bookingTicket: BookingTicketsInput, $bookingTicketVouchers: [BookingTicketVouchersInput], $bookingDateRequests: [BookingDateRequestsInput], $address: AddressesInput, $fellowTravelers: [FellowTravelersInput]) {
            saveBookingTicket(bookingTicket: $bookingTicket, bookingTicketVouchers: $bookingTicketVouchers, bookingDateRequests: $bookingDateRequests, address: $address, fellowTravelers: $fellowTravelers) {
                IsSuccess
                Message
                Guid
            }
        }`,
        CancelBookingTicket: gql`
        mutation cancelBookingTicket($id: ID, $userId: ID) {
            cancelBookingTicket(id: $id, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
    },
};
