<template lang="pug">
  .datepicker
    datepicker.p-0(
      :disabled="isDisabled"
      :inline="true"
      :bootstrap-styling="true"
      :language="lang"
      :monday-first="true"
      maximum-view="month"
      :full-month-name="true"
      @selected="selectDate($event)"
      @changedMonth="changeDate($event)"
      @changedYear="changeDate($event)"
      @changedDecade="changeDate($event)"
    )
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import EventBus from "@/views/event-bus";
import { de, en } from "vuejs-datepicker/dist/locale";

export default {
    name: "BookingDatePicker",
    components: {
        Datepicker,
    },
    props: {
        selectDate: {
            type: Function,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            localVoucherIds: this.voucherIds,
            exclusionPeriods: undefined,
            currentDate: moment().startOf("month").toDate(),
            lang: localStorage.localeSettings == "en" ? en : de,
            isLoading: false,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeLanguage",
                function (language) {
                    this.lang = language == "en" ? en : de;
                }.bind(this)
            );
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        changeDate(date) {
            try {
                if (date.year) {
                    return;
                } else if (date.month) {
                    this.currentDate = moment().month(date.month).toDate();
                } else if (moment(date).isValid()) {
                    this.currentDate = date;
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
