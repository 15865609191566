import gql from "graphql-tag";

export const BookingTicketVouchers = {
    Queries: {
        BookingTicketVouchers: gql`
        query bookingTicketVouchers($bookingTicketId: ID, $hostIds: [ID]) {
            bookingTicketVouchers(bookingTicketId: $bookingTicketId, hostIds: $hostIds) {
                Id
                VoucherId
                BookingTicketId
                BookingTicketVoucherStatusId
                CreatedAt
            }
        }`,
    },
    Mutations: {},
};
