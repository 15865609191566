<template lang="pug">
  tr(:class="{ 'text-danger': !isValid }")
    th {{ voucher.VoucherCode }}
    td
      span(v-if="order") {{ order.PaidAt | formatDateTime }}
    td
      span(v-if="product") {{ product.Nights }}
    td
      span(v-if="address") {{ address.LastName }}, {{ address.FirstName }}
    td
      span(v-if="voucher.IsSelfSale") touriDat-Gutschein Werbemaßnahme
      span(v-else-if="item") {{ item.SingleItemPriceGross - item.InsuranceAmount | formatEuro }}
    td
      label {{ $t("voucher.voucherStatus." + voucher.Status) }}
    td
      input.form-control(type="text" :value="voucher.BookedAt | formatDate" :placeholder="$t('voucher.tableColumn.arrivalOn')" v-if="isDisabled || !isValid || voucher.BookedAt" disabled)
      router-link.button.button-primary.button-tbook.w-100(:to="{ name: 'BookingTicketNew', query: { voucherId: voucher.Id } }" v-else) Buchung erstellen

    td
      .form-group.custom-form-group.align-self-center
        .input-group(@click="showNotesModal")
          input.form-control.custom-control.cursor-pointer(type="text" :value="voucher.BookingInfo" :placeholder="$t('voucher.tableColumn.notes')")
          .input-group-append
            button.button.button-primary.button-td-grey.button-icon
              font-awesome-icon(:icon="['fas','pen']")

    td
      router-link.button.button-primary.button-tdays(:to="{ name: 'VoucherDetails', params: { voucherGuid: voucher.Guid } }")
        | {{ $t("constants.buttons.show") }}

</template>

<script>
import VoucherStatusEnum from "../../../../lib/enums/voucherStatus/VoucherStatusEnum";
import VoucherBookingInfoModal from "@/views/modals/VoucherBookingInfoModal";
import ProductsComponent from "@/lib/components/products/ProductsComponent";
import OrdersComponent from "@/lib/components/orders/OrdersComponent";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import AddressesComponent from "@/lib/components/addresses/AddressesComponents";
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";

const moment = require("moment");

export default {
    name: "VouchersTableRow",
    components: {},
    props: {
        voucher: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            item: undefined,
            order: undefined,
            product: undefined,
            bookingTickets: [],
            address: undefined,

            VoucherStatusEnum: VoucherStatusEnum,
            moment: moment,
        };
    },
    mounted() {
        try {
            this.loadItem();
            this.loadProduct();
            this.loadOrder();
            this.loadBookingTickets();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadProduct() {
            try {
                const productsComponent = new ProductsComponent();
                const product = await productsComponent.getProduct(
                    this.$apollo,
                    this.voucher.ProductId
                );
                if (!product) {
                    return;
                }
                this.product = product;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadItem() {
            try {
                const itemsComponent = new ItemsComponent();
                const item = await itemsComponent.getItemByVoucherId(
                    this.$apollo,
                    this.voucher?.Id
                );
                if (!item) {
                    return;
                }
                this.item = item;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadOrder() {
            try {
                const ordersComponent = new OrdersComponent();
                const order = await ordersComponent.getOrderByVoucherId(
                    this.$apollo,
                    this.voucher?.Id
                );
                if (!order) {
                    return;
                }
                this.order = order;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTickets() {
            try {
                if (this.voucher.Id != "1464971") {
                    return;
                }
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTickets =
                    await bookingTicketsComponent.getBookingTickets(
                        this.$apollo,
                        undefined,
                        this.voucher?.Id
                    );
                if (!bookingTickets) {
                    return;
                }
                this.bookingTickets = bookingTickets;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadAddress() {
            try {
                const addressesComponent = new AddressesComponent();
                const address = await addressesComponent.getAddress(
                    this.$apollo,
                    this.bookingTicket?.AddressId
                );
                if (!address) {
                    return;
                }
                this.address = address;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        showNotesModal() {
            this.$modal.show(
                VoucherBookingInfoModal,
                {
                    voucher: this.voucher,
                },
                {
                    resizable: true,
                    height: "auto",
                }
            );
        },
    },
    computed: {
        bookingTicket() {
            try {
                return this.bookingTickets.find(
                    (bookingTicket) =>
                        bookingTicket.BookingTicketStatusId ==
                        BookingTicketStatusEnum.DatesBooked
                );
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        isDisabled() {
            try {
                return (
                    (!this.voucher.IsSelfSale &&
                        this.voucher.Status == VoucherStatusEnum.Created) ||
                    this.voucher.Status == VoucherStatusEnum.Cancelled ||
                    this.voucher.Status == VoucherStatusEnum.HostCancel
                );
            } catch (e) {
                console.error(e);
                return true;
            }
        },
        isValid() {
            try {
                return moment().isBefore(this.voucher?.ValidTill);
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
    watch: {
        bookingTicket() {
            try {
                this.loadAddress();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
