const {
    BookingTicketVouchers,
} = require("@/lib/graphql/bookingTicketVouchers/BookingTicketVouchers.ts");

class BookingTicketVouchersComponent {
    async getBookingTicketVouchers(apollo, bookingTicketId, hostIds) {
        try {
            if (
                !apollo ||
                (!bookingTicketId &&
                    (!hostIds || !Array.isArray(hostIds) || !hostIds.length))
            ) {
                return undefined;
            }
            const bookingTicketVouchers = await apollo
                .query({
                    query: BookingTicketVouchers.Queries.BookingTicketVouchers,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: bookingTicketId,
                        hostIds: hostIds,
                    },
                })
                .then(({ data }) => data?.bookingTicketVouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicketVouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = BookingTicketVouchersComponent;
