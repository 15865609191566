<template lang="pug">
  .voucher-code-input
    .row
      .col-12
        input.code-control(type="text" v-model="firstChar" maxlength=1 ref="1")
        input.code-control(type="text" v-model="secondChar" maxlength=1 ref="2")
        input.code-control(type="text" v-model="thirdChar" maxlength=1 ref="3")
        input.code-control(type="text" v-model="forthChar" maxlength=1 ref="4")
        input.code-control(type="text" v-model="fifthChar" maxlength=1 ref="5")
        input.code-control(type="text" v-model="sixthChar" maxlength=1 ref="6")

</template>

<script>
export default {
    name: "VoucherCodeInput",
    props: {
        checkVoucherCode: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            firstChar: "",
            secondChar: "",
            thirdChar: "",
            forthChar: "",
            fifthChar: "",
            sixthChar: "",
        };
    },
    watch: {
        firstChar() {
            try {
                if (!this.firstChar) {
                    return;
                }
                this.$refs["2"].focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        secondChar() {
            try {
                if (!this.secondChar) {
                    return;
                }
                this.$refs["3"].focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        thirdChar() {
            try {
                if (!this.thirdChar) {
                    return;
                }
                this.$refs["4"].focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        forthChar() {
            try {
                if (!this.forthChar) {
                    return;
                }
                this.$refs["5"].focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        fifthChar() {
            try {
                if (!this.fifthChar) {
                    return;
                }
                this.$refs["6"].focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        code() {
            try {
                if (this.code?.length < 6) {
                    return;
                }
                this.checkVoucherCode(this.code);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        code() {
            try {
                return `${this.firstChar}${this.secondChar}${this.thirdChar}${this.forthChar}${this.fifthChar}${this.sixthChar}`;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
