import { Hosts } from "../../graphql/hosts/Hosts.ts";

export class HostsComponent {
    async getHost(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const host = await apollo
                .query({
                    query: Hosts.Queries.Host,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.host)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return host;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHosts(
        apollo,
        hostPoolId,
        bookingTicketId,
        filter,
        hasToHaveActiveProducts,
        offset,
        limit
    ) {
        try {
            if (
                !apollo ||
                (!hostPoolId &&
                    !bookingTicketId &&
                    !filter &&
                    hasToHaveActiveProducts == undefined &&
                    !offset &&
                    !limit)
            ) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.Hosts,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostPoolId: hostPoolId,
                        bookingTicketId: bookingTicketId,
                        filter: filter,
                        hasToHaveActiveProducts: hasToHaveActiveProducts,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.hosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveHost(apollo, host, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!host) {
                return {
                    IsSuccess: false,
                    Message: "Keinen Gastgeber gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const savedHost = await apollo
                .mutate({
                    mutation: Hosts.Mutations.SaveHost,
                    fetchPolicy: "no-cache",
                    variables: {
                        host: host,
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.saveHost)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedHost) {
                return {
                    IsSuccess: false,
                    Message: "Der Gastgeber konnte nicht gespeichert werden.",
                };
            }
            return savedHost;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getAvailableHosts(apollo, userId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!userId) {
                return undefined;
            }
            const availableHosts = await apollo
                .query({
                    query: Hosts.Queries.AvailableHosts,
                    variables: {
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.availableHosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!availableHosts) {
                return undefined;
            }
            return availableHosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getAssignedHosts(apollo, userId) {
        try {
            if (!apollo || !userId) {
                return undefined;
            }
            const assignedHosts = await apollo
                .query({
                    query: Hosts.Queries.AssignedHosts,
                    fetchPolicy: "no-cache",
                    variables: {
                        userId: userId,
                    },
                })
                .then(({ data }) => data?.assignedHosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!assignedHosts) {
                return undefined;
            }
            return assignedHosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
