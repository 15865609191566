import gql from "graphql-tag";

const _props = `
    Id
    ProvisionFP
    Verkaufsgebuehren
    Match1
    PPLZ
    PORT
    PLAND
    RLAND
    PAdresse1
    PAdresse2
    PAdresse3
    PAdresse4
    RAdresse1
    RAdresse2
    RAdresse3
    RAdresse4
    RAdresse5
    RAdresse6
    RAdresse7
    RPLZ
    RORT
    Ansprechpartner
    TELEFON1
    TELEFON2
    TELEFAX1
    EMAIL1
    EMAIL2
    Match1
    Internetadresse1
    Internetadresse2
    HTMLTitel
    HTMLSubtitel
    HTMLBlock01
    HTMLBlock02
    HTMLBlock03
    HTMLBlock04
    ThirdPartyId
    Stars
    CityRegion
    Description
    Name
    Longitude
    Latitude
    LinkVideo
    ShopEmailBenachrichtigungAb
    IsActiveTouriBookCustomer
    NotificationTimeoutTill
    UStIDNr
    AccountOwner
    IBAN
    BIC
    QuellCodeExtras
    DistributionId
`;

export const Hosts = {
    Queries: {
        Host: gql`
        query host($id: ID) {
            host(id: $id) {
                ${_props}
            }
        }`,
        Hosts: gql`
        query hosts($hostPoolId: ID, $bookingTicketId: ID, $filter: String, $hasToHaveActiveProducts: Boolean, $offset: Int, $limit: Int) {
            hosts(hostPoolId: $hostPoolId, bookingTicketId: $bookingTicketId, filter: $filter, hasToHaveActiveProducts: $hasToHaveActiveProducts, offset: $offset, limit: $limit) {
                Id
                ProvisionFP
                Verkaufsgebuehren
                Match1
                PPLZ
                PORT
                PLAND
                RLAND
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                EMAIL1
                EMAIL2
                Match1
                Internetadresse1
                Internetadresse2
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04
                ThirdPartyId
                Stars
                CityRegion
                Description
                Name
                Longitude
                Latitude
                LinkVideo
                ShopEmailBenachrichtigungAb
                IsActiveTouriBookCustomer
                NotificationTimeoutTill
                UStIDNr
                AccountOwner
                IBAN
                BIC
                QuellCodeExtras
                DistributionId
            }
        }`,
        AvailableHosts: gql`
        query availableHosts($userId: ID) {
            availableHosts(userId: $userId) {
                Id
                Name
            }
        }`,
        AssignedHosts: gql`
        query assignedHosts($userId: ID) {
            assignedHosts(userId: $userId) {
                Id
                Name
            }
        }`,
        HostByProductContractId: gql`
        query hostByProductContractId($productContractId: ID) {
            hostByProductContractId(productContractId: $productContractId) {
                ${_props}
            }
        }`,
    },
    Mutations: {
        SaveHost: gql`
        mutation saveHost($host: HostsInput, $userId: ID) {
            saveHost(host: $host, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
    },
};
