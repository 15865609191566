import gql from "graphql-tag";

export const Vouchers = {
    Queries: {
        HostsVouchers: gql`
        query hostsVouchers(
            $hostIds: [ID]
            $filter: String
            $voucherStatus: [Int]
            $from: Date,
            $to: Date,
            $offset: Int
            $limit: Int
            $sort: String
        ) {
            hostsVouchers(
                hostIds: $hostIds
                filter: $filter
                voucherStatus: $voucherStatus
                from: $from
                to: $to
                offset: $offset
                limit: $limit
                sort: $sort
            ) {
                Id
                Guid
                ItemId
                ShippingMethod
                Status
                ProductId
                VoucherCode
                CreatedAt
                UpdatedAt
                LastSentOrPrintedAt
                BookedAt
                RedeemedAt
                CancelledAt
                CancelledBy
                InsolventAt
                TitleSpecial
                TitleFat
                TitleColored
                LongVoucherDescription
                LongInvisibleDescription
                Arrangement
                Services
                ServicesAddition
                Value
                BookingAvailability
                AdditionalInfo
                AdditionalInfoLink
                Adress1
                Adress2
                Adress4
                AdressCountry
                AdressPostalCode
                AdressCity
                AdressPhone
                AdressFax
                AdressEmail
                AddressId
                AddressAdvisorSalesId
                OrderId
                VoucherHostMailSentDate
                PaymentNotificationHostMailSentDate
                CancelHostMailSentDate
                CancelVoucherCustomerMailSentDate
                BookingInfo
                BookingUser
                BookingAddressID
                InvoiceDate
                InvoiceCancelDate
                ReverseTransactionAt
                BookingInfoEditedBy
                HolidayServices
                AdditionalHolidayServices
                IsSelfSale
                PayoutDate
                HostAddressCancelledAt
                ValidTill
                CancelVoucherToDoGeneratedAt
            }
        }`,
        HostsVouchersCount: gql`
        query hostsVouchersCount(
            $hostIds: [ID]
            $filter: String
            $voucherStatus: [Int]
            $from: Date
            $to: Date
        ) {
            hostsVouchersCount(
                hostIds: $hostIds
                filter: $filter
                voucherStatus: $voucherStatus
                from: $from
                to: $to
            )
        }`,
        HostsVouchersHtml: gql`
        query hostsVouchersHtml(
            $hostIds: [ID]
            $filter: String
            $voucherStatus: [Int],
            $from: Date,
            $to: Date,
            $sort: String,
            $offset: Int,
            $limit: Int,
            $includeTableHeader: Boolean
        ) {
            hostsVouchersHtml(
                hostIds: $hostIds,
                filter: $filter,
                voucherStatus: $voucherStatus,
                from: $from,
                to: $to,
                sort: $sort,
                offset: $offset,
                limit: $limit,
                includeTableHeader: $includeTableHeader 
            )
        }`,
        Voucher: gql`
        query voucher($id: ID, $guid: String) {
            voucher(id: $id, guid: $guid) {
                Id
                Guid
                ItemId
                ShippingMethod
                Status
                ProductId
                VoucherCode
                CreatedAt
                UpdatedAt
                LastSentOrPrintedAt
                BookedAt
                RedeemedAt
                CancelledAt
                CancelledBy
                InsolventAt
                TitleSpecial
                TitleFat
                TitleColored
                LongVoucherDescription
                LongInvisibleDescription
                Arrangement
                Services
                ServicesAddition
                Value
                BookingAvailability
                AdditionalInfo
                AdditionalInfoLink
                Adress1
                Adress2
                Adress4
                AdressCountry
                AdressPostalCode
                AdressCity
                AdressPhone
                AdressFax
                AdressEmail
                AddressId
                AddressAdvisorSalesId
                OrderId
                VoucherHostMailSentDate
                PaymentNotificationHostMailSentDate
                CancelHostMailSentDate
                CancelVoucherCustomerMailSentDate
                BookingInfo
                BookingUser
                BookingAddressID
                InvoiceDate
                InvoiceCancelDate
                ReverseTransactionAt
                BookingInfoEditedBy
                HolidayServices
                AdditionalHolidayServices
                IsSelfSale
                PayoutDate
                HostAddressCancelledAt
                ValidTill
                CancelVoucherToDoGeneratedAt
            }
        }`,
        Vouchers: gql`
        query vouchers($hostId: ID, $productId: ID, $bookingTicketId: ID, $voucherStatus: [ID], $filter: String, $offset: Int, $limit: Int) {
            vouchers(hostId: $hostId, productId: $productId, bookingTicketId: $bookingTicketId, voucherStatus: $voucherStatus, filter: $filter, offset: $offset, limit: $limit) {
                Id
                Guid
                ItemId
                ShippingMethod
                Status
                ProductId
                VoucherCode
                CreatedAt
                UpdatedAt
                LastSentOrPrintedAt
                BookedAt
                RedeemedAt
                CancelledAt
                CancelledBy
                InsolventAt
                TitleSpecial
                TitleFat
                TitleColored
                LongVoucherDescription
                LongInvisibleDescription
                Arrangement
                Services
                ServicesAddition
                Value
                BookingAvailability
                AdditionalInfo
                AdditionalInfoLink
                Adress1
                Adress2
                Adress4
                AdressCountry
                AdressPostalCode
                AdressCity
                AdressPhone
                AdressFax
                AdressEmail
                AddressId
                AddressAdvisorSalesId
                OrderId
                VoucherHostMailSentDate
                PaymentNotificationHostMailSentDate
                CancelHostMailSentDate
                CancelVoucherCustomerMailSentDate
                BookingInfo
                BookingUser
                BookingAddressID
                InvoiceDate
                InvoiceCancelDate
                ReverseTransactionAt
                BookingInfoEditedBy
                HolidayServices
                AdditionalHolidayServices
                IsSelfSale
                PayoutDate
                HostAddressCancelledAt
                ValidTill
                CancelVoucherToDoGeneratedAt
            }
        }`,
    },
    Mutations: {
        SaveVoucher: gql`
        mutation saveVoucher($voucher: VouchersInput) {
            saveVoucher(voucher: $voucher) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
