const {
    BookingTicketStatus,
} = require("@/lib/graphql/bookingTicketStatus/BookingTicketStatus.ts");

class BookingTicketStatusComponent {
    async getBookingTicketStatusById(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const bookingTicketStatus = await apollo
                .query({
                    query: BookingTicketStatus.Queries.BookingTicketStatusById,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.bookingTicketStatusById)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicketStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getBookingTicketStatus(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const bookingTicketStatus = await apollo
                .query({
                    query: BookingTicketStatus.Queries.BookingTicketStatus,
                    fetchPolicy: "no-cache",
                })
                .then(({ data }) => data?.bookingTicketStatus)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicketStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = BookingTicketStatusComponent;
