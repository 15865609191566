<template lang="pug">
.fellow-traveler(v-if="fellowTraveler.FirstName && fellowTraveler.LastName && fellowTraveler.BirthDate && fellowTraveler.Country")
  .row
    .col-12
      font-awesome-icon.mr-2(:icon="['fas', 'user-plus']" size="lg")
      span {{ fellowTraveler.FirstName }} {{ fellowTraveler.LastName }}, {{ fellowTraveler.BirthDate | formatDate($t('lang')) }}, {{ fellowTraveler.Country }}

</template>

<script>
export default {
    name: "FellowTraveler",
    props: {
        fellowTraveler: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped></style>
