<template lang="pug">
  .voucher-preview(v-if="localVoucher")
    .row
      .col-12
        h5.font-weight-bold(v-if="host") {{ host.Name }} {{ host.CityRegion }}
    .row
      .col-12
        h5 {{ localVoucher.Id }}: {{ localVoucher.Arrangement }}

    template(v-if="!isPreview")
      .row
        .col-12
          hr
      .row.mt-2(v-if="holidayServices && Array.isArray(holidayServices) && holidayServices.length")
        .col-12
          h6 {{ $t("customer-data.services") }}
          ul
            li(v-for="holidayService in holidayServices" v-if="holidayService.trim()") {{ holidayService.trim() }}
      .row(v-if="additionalHolidayServices && Array.isArray(additionalHolidayServices) && additionalHolidayServices.length")
        .col-12
          h6(style="font-style: italic;") {{ $t("customer-data.additionalServices") }}
          ul
            li(v-for="additionalHolidayService in additionalHolidayServices" v-if="additionalHolidayService.trim()") {{ additionalHolidayService.trim() }}
      .row
        .col-12
          h6 {{ $t("customer-data.bookability") }}
          p {{ localVoucher.BookingAvailability }}

</template>

<script>
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import ProductsComponent from "@/lib/components/products/ProductsComponent";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";

export default {
    name: "VoucherPreview",
    props: {
        voucherId: {
            type: [String, Number],
            required: false,
        },
        voucher: {
            type: Object,
            required: false,
        },
        isPreview: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            localVoucher: this.voucher,
            product: undefined,
            host: undefined,
        };
    },
    async mounted() {
        try {
            if (this.voucherId) {
                this.loadVoucher();
            }
            this.loadProduct();
            this.loadHost();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucher() {
            try {
                if (!this.voucherId) {
                    return;
                }
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.voucherId
                );
                if (!voucher) {
                    return;
                }
                this.localVoucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProduct() {
            try {
                if (!this.localVoucher?.ProductId) {
                    return;
                }
                const productsComponent = new ProductsComponent();
                const product = await productsComponent.getProduct(
                    this.$apollo,
                    this.localVoucher?.ProductId
                );
                if (!product) {
                    return;
                }
                this.product = product;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadHost() {
            try {
                if (!this.localVoucher?.AddressId) {
                    return;
                }
                const hostsComponent = new HostsComponent();
                const host = await hostsComponent.getHost(
                    this.$apollo,
                    this.localVoucher?.AddressId
                );
                if (!host) {
                    return;
                }
                this.host = host;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        localVoucher() {
            try {
                if (!this.localVoucher?.Id) {
                    return;
                }
                this.loadProduct();
                this.loadHost();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        holidayServices() {
            try {
                if (!this.localVoucher || !this.localVoucher.HolidayServices) {
                    return undefined;
                }
                return this.localVoucher.HolidayServices.split("\n");
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        additionalHolidayServices() {
            try {
                if (
                    !this.localVoucher ||
                    !this.localVoucher.AdditionalHolidayServices
                ) {
                    return undefined;
                }
                return this.localVoucher.AdditionalHolidayServices.split("\n");
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
