<template lang="pug">
  .booking-ticket-voucher
    .row(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays && voucher")
      .col-12
        font-awesome-icon.mr-2(:icon="['fas', 'file-lines']" size="lg")
        router-link(:to="{ name: 'VoucherDetails', params: { voucherGuid: voucher.Guid } }" target="_blank") {{ voucher.Id }}: {{ voucher.Arrangement }}

    .ml-5.mt-2
      .row
        .col-12
          h6.font-weight-bold Buchungsdaten
      booking-date-requests(
        :booking-ticket="bookingTicket"
        :booking-ticket-voucher="bookingTicketVoucher"
        :booking-date-requests="localBookingDateRequests"
      )
    .ml-5.mt-2
      .row
        .col-12
          h6.font-weight-bold Mitreisende
      fellow-travelers(
        v-if="fellowTravelers && Array.isArray(fellowTravelers) && fellowTravelers.length"
        :fellow-travelers="fellowTravelers"
      )

</template>

<script>
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";
import { BookingTicketTypesEnum } from "@/lib/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import FellowTravelersComponent from "@/lib/components/fellowTravelers/FellowTravelersComponent";
import FellowTravelers from "@/views/fellowTravelers/FellowTravelers.vue";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";

export default {
    name: "BookingTicketVoucher",
    components: { FellowTravelers, BookingDateRequests },
    props: {
        bookingTicket: {
            type: Object,
            required: true,
        },
        bookingTicketVoucher: {
            type: Object,
            required: true,
        },
        bookingDateRequests: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            localBookingDateRequests: this.bookingDateRequests.filter(
                (b) => b.BookingTicketVoucherId == this.bookingTicketVoucher?.Id
            ),
            fellowTravelers: [],
            voucher: undefined,
            BookingTicketTypesEnum: BookingTicketTypesEnum,
        };
    },
    async mounted() {
        try {
            this.loadFellowTravelers();
            this.loadVoucher();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadFellowTravelers() {
            try {
                const fellowTravelersComponent = new FellowTravelersComponent();
                const fellowTravelers =
                    await fellowTravelersComponent.getFellowTravelers(
                        this.$apollo,
                        undefined,
                        this.bookingTicketVoucher.Id
                    );
                if (!fellowTravelers) {
                    return;
                }
                this.fellowTravelers = fellowTravelers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.bookingTicketVoucher.VoucherId
                );
                if (!voucher) {
                    return;
                }
                this.voucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        bookingDateRequests() {
            try {
                this.localBookingDateRequests = this.bookingDateRequests.filter(
                    (b) =>
                        b.BookingTicketVoucherId ==
                        this.bookingTicketVoucher?.Id
                );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
