<template lang="pug">
  .booking-ticket-panel(v-if="bookingTicket")
    .booking-ticket-panel-header
      .row.w-100
        .col
          span {{ $t("booking") }}:&nbsp;
          span.font-weight-bold {{ bookingTicket.Id }}
          span(v-if="bookingTicket.IsBindingBooking") &nbsp;({{ $t("bindingBooking") }})
        .col
          span {{ $t("createdAt") }}:&nbsp;
          span.font-weight-bold {{ bookingTicket.CreatedAt | formatDate($t('lang')) }}
        .col(v-if="bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.DifferentArrivalDays")
          span {{ $t("vouchers") }}:&nbsp;
          template(v-for="voucher in vouchers")
            span.font-weight-bold(v-if="vouchers.indexOf(voucher)") ,&nbsp;
            router-link(
              :to="{ name: 'VoucherDetails', params: { voucherGuid: voucher.Guid } }"
              target="_blank"
            )
              span.font-weight-bold {{ voucher.Id }}
    .booking-ticket-panel-body.m-2
      template(v-if="address")
        .row
          .col-12
            h5 {{ $t("mainTraveler") }}
        .row
          .col-12
            font-awesome-icon.mr-2(:icon="['fas', 'user']" size="lg")
            span {{ addressString }}
            span(v-if="address.Country") &nbsp;{{ address.Country }}
            span(v-if="address.BirthDate") ,&nbsp;{{ address.BirthDate | formatDate($t('lang')) }}
            span(v-if="address.Phone") ,&nbsp;{{ address.Phone }}

      template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
        booking-ticket-host.mt-3(
          v-for="host in hosts"
          :booking-ticket="bookingTicket"
          :host="host"
          :booking-date-requests="bookingDateRequests"
          :key="hosts.indexOf(host)"
        )
      template(v-else)
        .row.mt-3
          .col-12
            h5 {{ $t("fellowTravelers") }}
        fellow-travelers(
          v-if="fellowTravelers && Array.isArray(fellowTravelers) && fellowTravelers.length"
          :fellow-travelers="fellowTravelers"
        )

        .row.mt-3
          .col-12
            h5 {{ $t("bookingPeriods") }}
        .row
          .col-12
            booking-date-requests(
              :booking-ticket="bookingTicket"
              :booking-ticket-vouchers="bookingTicketVouchers"
              :booking-date-requests="bookingDateRequests"
              :show-booking-date-request-status="true"
            )

      .row
        .col-12
          .float-right
            button.button.button-primary.button-tdays(
              v-if="isSaveBookingTicketAvailable"
              @click="saveBookingTicket"
            )
              font-awesome-icon.mr-2(:icon="['fas', 'floppy-disk']")
              span {{ $t("saveBooking") }}

</template>

<script>
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import BookingTicketVouchersComponent from "@/lib/components/bookingTicketVouchers/BookingTicketVouchersComponent";
import AddressesComponent from "@/lib/components/addresses/AddressesComponents";
import { BookingTicketTypesEnum } from "@/lib/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import BookingTicketVouchers from "@/views/bookingTicketVouchers/BookingTicketVouchers.vue";
import BookingTicketVoucher from "@/views/bookingTicketVouchers/BookingTicketVoucher.vue";
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";
import FellowTravelersComponent from "@/lib/components/fellowTravelers/FellowTravelersComponent";
import BookingDateRequestsComponent from "@/lib/components/bookingDateRequests/BookingDateRequestsComponent";
import EventBus from "@/views/event-bus";
import BookingTicketHost from "@/views/bookingTickets/BookingTicketHost.vue";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";
import FellowTravelers from "@/views/fellowTravelers/FellowTravelers.vue";
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { BookingTicketVoucherStatusEnum } from "@/lib/enums/bookingTicketVoucherStatus/BookingTicketVoucherStatusEnum.ts";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";

export default {
    name: "BookingTicketPanel",
    components: {
        FellowTravelers,
        BookingTicketHost,
        BookingDateRequests,
        BookingTicketVoucher,
        BookingTicketVouchers,
    },
    props: {
        bookingTicketId: {
            type: [String, Number],
            required: true,
        },
        hostIds: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            bookingTicket: undefined,
            bookingTicketVouchers: [],
            vouchers: [],
            bookingDateRequests: [],
            address: undefined,
            fellowTravelers: [],
            hosts: [],

            BookingTicketTypesEnum: BookingTicketTypesEnum,
        };
    },
    async mounted() {
        try {
            this.loadBookingTicket();
            this.loadBookingTicketVouchers();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadBookingTicket() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTicket =
                    await bookingTicketsComponent.getBookingTicket(
                        this.$apollo,
                        this.bookingTicketId
                    );
                if (!bookingTicket) {
                    return;
                }
                this.bookingTicket = bookingTicket;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketVouchers() {
            try {
                if (
                    this.bookingTicket?.BookingTicketTypeId ==
                    BookingTicketTypesEnum.DifferentArrivalDays
                ) {
                    return;
                }
                const bookingTicketVouchersComponent =
                    new BookingTicketVouchersComponent();
                const bookingTicketVouchers =
                    await bookingTicketVouchersComponent.getBookingTicketVouchers(
                        this.$apollo,
                        this.bookingTicketId,
                        this.hostIds
                    );
                if (!bookingTicketVouchers) {
                    return;
                }
                this.bookingTicketVouchers = bookingTicketVouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVouchers() {
            try {
                const vouchersComponent = new VouchersComponent();
                const vouchers = await vouchersComponent.getVouchers(
                    this.$apollo,
                    undefined,
                    undefined,
                    this.bookingTicket?.Id
                );
                if (!vouchers) {
                    return;
                }
                this.vouchers = vouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadHosts() {
            try {
                if (
                    this.bookingTicket?.BookingTicketTypeId !=
                    BookingTicketTypesEnum.DifferentArrivalDays
                ) {
                    return;
                }
                const hostsComponent = new HostsComponent();
                const hosts = await hostsComponent.getHosts(
                    this.$apollo,
                    undefined,
                    this.bookingTicket.Id
                );
                if (!hosts) {
                    return;
                }
                this.hosts = hosts;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingDateRequests() {
            try {
                const bookingDateRequestsComponent =
                    new BookingDateRequestsComponent();
                const bookingDateRequests =
                    await bookingDateRequestsComponent.getBookingDateRequests(
                        this.$apollo,
                        this.bookingTicket?.Id
                    );
                if (!bookingDateRequests) {
                    return;
                }
                this.bookingDateRequests = bookingDateRequests;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadAddress() {
            try {
                const addressesComponent = new AddressesComponent();
                const address = await addressesComponent.getAddress(
                    this.$apollo,
                    this.bookingTicket?.AddressId
                );
                if (!address) {
                    return;
                }
                this.address = address;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadFellowTravelers() {
            try {
                const fellowTravelersComponent = new FellowTravelersComponent();
                const fellowTravelers =
                    await fellowTravelersComponent.getFellowTravelers(
                        this.$apollo,
                        this.bookingTicket?.Id
                    );
                if (!fellowTravelers) {
                    return;
                }
                this.fellowTravelers = fellowTravelers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveBookingTicket() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedBookingTicket =
                    await bookingTicketsComponent.saveBookingTicket(
                        this.$apollo,
                        this.bookingTicket,
                        this.bookingTicketVouchers,
                        this.bookingDateRequests
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedBookingTicket) {
                    return this.$alert(
                        "Die Buchung konnte nicht gespeichert werden."
                    );
                }
                if (!savedBookingTicket.IsSuccess) {
                    return this.$alert(savedBookingTicket.Message);
                }
                if (savedBookingTicket.IsSuccess) {
                    this.bookingTicket = undefined;
                    this.loadBookingTicket();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        "bookingTicket.Id"() {
            try {
                this.loadBookingTicketVouchers();
                this.loadVouchers();
                this.loadAddress();
                this.loadFellowTravelers();
                this.loadBookingDateRequests();
                this.loadHosts();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        addressString() {
            try {
                if (!this.address) {
                    return undefined;
                }
                let formOfAddress = this.address.FormOfAddress;
                if (formOfAddress?.toLowerCase() == "herr") {
                    formOfAddress = this.$t("customer-data.gender.m");
                } else if (formOfAddress?.toLowerCase() == "frau") {
                    formOfAddress = this.$t("customer-data.gender.f");
                }
                const addressString = `${formOfAddress} ${this.address.FirstName} ${this.address.LastName}, ${this.address.Street}, ${this.address.PostalCode} ${this.address.City}`;
                return addressString;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        isSaveBookingTicketAvailable() {
            try {
                if (
                    this.bookingTicket?.BookingTicketStatusId ==
                        BookingTicketStatusEnum.DatesRequested ||
                    this.bookingTicket?.BookingTicketStatusId ==
                        BookingTicketStatusEnum.DatesCancelRequested
                ) {
                    return true;
                } else if (
                    this.bookingTicketVouchers.some(
                        (bookingTicketVoucher) =>
                            bookingTicketVoucher.BookingTicketVoucherStatusId ==
                                BookingTicketVoucherStatusEnum.DatesRequested ||
                            bookingTicketVoucher.BookingTicketVoucherStatusId ==
                                BookingTicketVoucherStatusEnum.DatesCancelRequested
                    )
                ) {
                    return true;
                }
                return false;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>
