import Vue from "vue";
import VueRouter from "vue-router";
import VueApollo from "vue-apollo";
import VModal from "vue-js-modal";
import multiselect from "vue-multiselect";
import Popover from "vue-js-popover";
import VueNativeNotification from "vue-native-notification";
import VueSession from "vue-session";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import "bootstrap";
import Utils from "./utils";

import App from "./views/App.vue";
import Home from "./views/home.vue";
import Login from "./views/login.vue";
import ResetPassword from "./views/reset-password.vue";
import NotFound from "./views/not-found.vue";

import ProductsList from "./views/pages/product/ProductsList.vue";
import ProductDetail from "./views/pages/product/detail.vue";

import SalesDetail from "./views/pages/sales/detail.vue";

import AccountData from "./views/pages/account/data.vue";
import AccountSecurity from "./views/pages/account/security.vue";
import AccountDatasheet from "./views/pages/account/datasheet.vue";
import UserDetails from "./views/pages/account/users/UserDetails.vue";
import UsersList from "./views/pages/account/users/UsersList.vue";
import AccountSettlements from "./views/pages/account/settlements.vue";
import AccountDocuments from "./views/pages/account/documents.vue";
import AccountImages from "./views/pages/account/images.vue";
import AccountProductContracts from "./views/pages/account/productContracts.vue";
import AccountNotifications from "./views/pages/account/notifications/NotificationsList.vue";
import NotificationDetails from "./views/pages/account/notifications/NotificationDetails.vue";
import AccountEmailSettings from "./views/pages/account/emailsettings.vue";

import InitialContact from "./views/pages/initial-contact.vue";

import ProductTableColumn from "./views/pages/product/table-column.vue";
import TouriBookDateRequestTemplate from "./views/partials/touribook/date-request-template.vue";
import ProductSelectOption from "./views/pages/sales/select-option.vue";
import ProductListItemCheckbox from "./views/pages/product/list-item-checkbox.vue";
import SalesProductChartComponent from "./views/pages/sales/product-chart-component.vue";
import HostSelect from "./views/partials/host/host-select.vue";

import tDLoadingOverlay from "./views/partials/loading/tD-loading-overlay.vue";
import tDLoading from "./views/partials/loading/tD-loading.vue";
import pagination from "./views/partials/pagination.vue";
import pagesize from "./views/partials/pagesize.vue";
import tableFilter from "./views/partials/table-filter.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);

import VueLogger from "vuejs-logger";

const appMode = process.env.VUE_APP_MODE;

const options = {
    isEnabled: true,
    logLevel: appMode === "production" ? "warn" : "debug",
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: "|",
    showConsoleColors: true,
};
Vue.use(VueLogger, options);
Vue.use(VueSession, {
    persist: true,
});

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Login", Login);
Vue.component("ResetPassword", ResetPassword);
Vue.component("InitialContact", InitialContact);
Vue.component("product-table-column", ProductTableColumn);
Vue.component("touribook-date-request-template", TouriBookDateRequestTemplate);
Vue.component("sales-select-option", ProductSelectOption);
Vue.component("product-list-item-checkbox", ProductListItemCheckbox);
Vue.component("sales-product-chart-component", SalesProductChartComponent);
Vue.component("host-select", HostSelect);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("multiselect", multiselect);
Vue.component("tD-loading-overlay", tDLoadingOverlay);
Vue.component("tD-loading", tDLoading);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("pagination", pagination);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("pagesize", pagesize);
Vue.component("tableFilter", tableFilter);

import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexchart", VueApexCharts);

Vue.use(TextareaAutosize);
Vue.use(VueNativeNotification, {
    // Automatic permission request before
    // showing notification (default: true)
    requestOnNotify: true,
});
Vue.use(Popover);
Vue.use(VueApollo);
Vue.use(VueRouter);
Vue.use(VModal);

const Config = require("./config/config"),
    conf = Config();

const customFetch = (uri, options) => {
    return fetch(uri, options).then((response) => {
        if (response.status >= 500) {
            // or handle 400 errors
            return Promise.reject(response.status);
        }
        return response;
    });
};

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: conf.graphQl.uri,
    fetch: customFetch,
});

// Cache implementation
const cache = new InMemoryCache({
    addTypename: false,
});

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
});

import TextareaAutosize from "vue-textarea-autosize";

Vue.use(TextareaAutosize);

import VouchersList from "@/views/pages/vouchers/VouchersList.vue";
import VoucherDetails from "@/views/pages/vouchers/VoucherDetails.vue";

// Vue Routing
const router = new VueRouter({
    mode: "history",
    linkExactActiveClass: "active",
    base: __dirname,
    routes: [
        {
            path: "/login",
            name: Login.name,
            component: Login,
            meta: {
                isLogin: true,
            },
        },
        {
            path: "/reset-password",
            name: ResetPassword.name,
            component: ResetPassword,
            meta: {
                isResetPassword: true,
            },
        },
        {
            path: "/initial-contact",
            name: InitialContact.name,
            component: InitialContact,
            meta: {
                isInitialContact: true,
            },
        },
        {
            path: "/",
            component: Home,
            name: Home.name,
            alias: "/home",
        },
        {
            path: "/products",
            component: ProductsList,
            name: ProductsList.name,
        },
        {
            path: "/products/:productGuid",
            component: ProductDetail,
            name: ProductDetail.name,
            props: true,
        },
        {
            path: "/vouchers",
            name: VouchersList.name,
            component: VouchersList,
        },
        {
            path: "/vouchers/:voucherGuid",
            name: VoucherDetails.name,
            component: VoucherDetails,
            props: true,
        },
        {
            path: "/sales/detail/",
            name: SalesDetail.name,
            component: SalesDetail,
        },
        {
            path: "/touribook/list",
            component: BookingTicketsList,
            name: BookingTicketsList.name,
        },
        {
            path: "/touriBook/booking",
            component: BookingTicketDetails,
            name: "BookingTicketNew",
        },
        {
            path: "/exclusion-periods",
            component: ExclusionPeriods,
            name: ExclusionPeriods.name,
        },
        {
            path: "/account/data/",
            component: AccountData,
            name: AccountData.name,
        },
        {
            path: "/account/datasheet",
            component: AccountDatasheet,
            name: AccountDatasheet.name,
        },
        {
            path: "/account/security",
            component: AccountSecurity,
            name: AccountSecurity.name,
        },
        {
            path: "/account/users/list",
            component: UsersList,
            name: UsersList.name,
        },
        {
            path: "/account/users/new",
            component: UserDetails,
            name: UserDetails.name,
            props: true,
        },
        {
            path: "/account/users/detail/:userId",
            component: UserDetails,
            name: UserDetails.name,
            props: true,
        },
        {
            path: "/account/settlements",
            component: AccountSettlements,
            name: AccountSettlements.name,
        },
        {
            path: "/account/documents",
            component: AccountDocuments,
            name: AccountDocuments.name,
        },
        {
            path: "/account/images",
            component: AccountImages,
            name: AccountImages.name,
        },
        {
            path: "/account/contracts",
            component: AccountProductContracts,
            name: AccountProductContracts.name,
        },
        {
            path: "/account/contracts/:productContractId",
            props: true,
            component: ProductContractEditor,
            name: ProductContractEditor.name,
        },
        {
            path: "/account/notifications",
            component: AccountNotifications,
            name: AccountNotifications.name,
        },
        {
            path: "/account/notifications/detail/:notificationId",
            component: NotificationDetails,
            name: NotificationDetails.name,
            props: true,
        },
        {
            path: "/account/emailsettings",
            component: AccountEmailSettings,
            name: AccountEmailSettings.name,
        },
        {
            path: "/logout",
            beforeEnter(to, from, next) {
                router.app.$session.destroy();
                next({ name: "Login" });
            },
        },
        {
            path: "/*",
            component: NotFound,
            name: NotFound.name,
        },
    ],
});

const moment = require("moment");
router.beforeEach((to, from, next) => {
    router.app.$modal.hideAll();
    if (
        to.name === "Login" ||
        to.name === "InitialContact" ||
        to.name === "ResetPassword"
    ) {
        next();
    } else if (
        !router.app.$session.exists() ||
        !router.app.$session.get("userId") ||
        moment(router.app.$session.get("validTill")).isBefore(new Date())
    ) {
        router.app.$session.destroy();
        const query = {
            redirect: to.fullPath,
        };
        if (to.query.guid) {
            query.guid = to.query.guid;
        }
        if (to.query.user) {
            query.user = to.query.user;
        }
        next({
            name: "Login",
            query: query,
        });
    } else {
        router.app.$session.set(
            "validTill",
            moment(new Date()).add(12, "hours").toDate()
        );
        next();
    }
});

router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload();
    }
}); // onError

import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import ProductContractEditor from "@/views/modals/productContract/editor/product-contract-editor.vue";
import BookingTicketsList from "@/views/bookingTickets/BookingTicketsList.vue";
import BookingTicketDetails from "@/views/bookingTickets/BookingTicketDetails.vue";
import ExclusionPeriods from "@/views/pages/exclusionPeriods/ExclusionPeriods.vue";

// Vue Filter
Vue.filter(
    Utils.vueFilter.formatInputDateFilter.name,
    Utils.vueFilter.formatInputDateFilter.func
);
Vue.filter(
    Utils.vueFilter.formatDateFilter.name,
    Utils.vueFilter.formatDateFilter.func
);
Vue.filter(
    Utils.vueFilter.formatDateTimeFilter.name,
    Utils.vueFilter.formatDateTimeFilter.func
);
Vue.filter(
    Utils.vueFilter.formatDatePickerValueFilter.name,
    Utils.vueFilter.formatDatePickerValueFilter.func
);
Vue.filter(
    Utils.vueFilter.formatDateWithDayFilter.name,
    Utils.vueFilter.formatDateWithDayFilter.func
);
Vue.filter(
    Utils.vueFilter.formatPercentageFilter.name,
    Utils.vueFilter.formatPercentageFilter.func
);
Vue.filter(
    Utils.vueFilter.formatEuroFilter.name,
    Utils.vueFilter.formatEuroFilter.func
);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

const i18n = Utils.vueI18N();
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert, {
    customClass: {
        confirmButton: "button button-primary button-tdays",
        cancelButton: "button button-primary button-tpics ml-3",
        popup: "alert-popup",
    },
    //cancelButtonText: i18n.t("buttons.cancel"),
    buttonsStyling: false,
});

/* eslint-disable no-new */
new Vue({
    el: "#app",
    router,
    apolloProvider,
    i18n,
    render: (h) => h(App),
    created() {
        if (!this.localStorage) {
            return localStorage.setItem(
                "localeSettings",
                navigator.language.substring(0, 2)
            );
        }
        const locale = this.localStorage.getItem("localeSettings");
        if (locale) {
            this.$i18n.locale = locale;
        } else if (navigator.language) {
            localStorage.setItem(
                "localeSettings",
                navigator.language.substring(0, 2)
            );
            this.$i18n.locale = navigator.language.substring(0, 2);
        }

        this.$log.warn(
            `started App in ${appMode} - Mode... waiting for interaction`
        );
    },
});
