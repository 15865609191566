const { Addresses } = require("@/lib/graphql/addresses/Addresses.ts");

class AddressesComponent {
    async getAddress(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const address = await apollo
                .query({
                    query: Addresses.Queries.Address,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.address)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return address;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getAddresses(apollo, filter, offset, limit) {
        try {
            if (!apollo && !filter && !offset && !limit) {
                return undefined;
            }
            const addresses = await apollo
                .query({
                    query: Addresses.Queries.Addresses,
                    variables: {
                        filter: filter,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.addresses)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!addresses) {
                return undefined;
            }
            return addresses;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = AddressesComponent;
