const {
    BookingTickets,
} = require("@/lib/graphql/bookingTickets/BookingTickets.ts");

class BookingTicketsComponent {
    async saveBookingTicket(
        apollo,
        bookingTicket,
        bookingTicketVouchers,
        bookingDateRequests,
        address,
        fellowTravelers
    ) {
        try {
            if (!apollo || !bookingTicket) {
                return undefined;
            }
            const savedBookingTicket = await apollo
                .mutate({
                    mutation: BookingTickets.Mutations.SaveBookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicket: bookingTicket,
                        bookingTicketVouchers: bookingTicketVouchers,
                        bookingDateRequests: bookingDateRequests,
                        address: address,
                        fellowTravelers: fellowTravelers,
                    },
                })
                .then(({ data }) => data?.saveBookingTicket)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return savedBookingTicket;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async requestCancelBookingTicket(apollo, bookingTicketId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!bookingTicketId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Buchungsticket-Id gefunden.",
                };
            }
            const requestedCancelBookingTicket = await apollo
                .mutate({
                    mutation:
                        BookingTickets.Mutations.RequestCancelBookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: bookingTicketId,
                    },
                })
                .then(({ data }) => data?.requestCancelBookingTicket)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!requestedCancelBookingTicket) {
                return {
                    IsSuccess: false,
                    Message: "Die Stornoanfrage konnte nicht versendet werden.",
                };
            }
            return requestedCancelBookingTicket;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getBookingTickets(
        apollo,
        hostIds,
        voucherId,
        bookingTicketStatusIds,
        filter,
        offset,
        limit
    ) {
        try {
            if (
                !apollo &&
                !voucherId(
                    !hostIds || !Array.isArray(hostIds) || !hostIds.length
                ) &&
                (!bookingTicketStatusIds ||
                    !Array.isArray(bookingTicketStatusIds) ||
                    !bookingTicketStatusIds.length) &&
                !filter
            ) {
                return undefined;
            }
            const bookingTickets = await apollo
                .query({
                    query: BookingTickets.Queries.BookingTickets,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        voucherId: voucherId,
                        bookingTicketStatusIds: bookingTicketStatusIds,
                        filter: filter,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.bookingTickets)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTickets;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getBookingTicketsCount(
        apollo,
        hostIds,
        bookingTicketStatusIds,
        filter
    ) {
        try {
            if (!apollo) {
                return undefined;
            }
            const bookingTicketsCount = await apollo
                .query({
                    query: BookingTickets.Queries.BookingTicketsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostIds: hostIds,
                        bookingTicketStatusIds: bookingTicketStatusIds,
                        filter: filter,
                    },
                })
                .then(({ data }) => data?.bookingTicketsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicketsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getBookingTicket(apollo, id, guid) {
        try {
            if (!apollo && !id && !guid) {
                return undefined;
            }
            const bookingTicket = await apollo
                .query({
                    query: BookingTickets.Queries.BookingTicket,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                        guid: guid,
                    },
                })
                .then(({ data }) => data?.bookingTicket)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return bookingTicket;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = BookingTicketsComponent;
