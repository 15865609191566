<template lang="pug">
  .booking-ticket-vouchers(v-if="bookingTicketVouchers && Array.isArray(bookingTicketVouchers) && bookingTicketVouchers.length")
    booking-ticket-voucher(
      v-for="bookingTicketVoucher in bookingTicketVouchers"
      :booking-ticket="bookingTicket"
      :booking-ticket-voucher="bookingTicketVoucher"
      :booking-date-requests="bookingDateRequests.filter((bookingDateRequest) => bookingDateRequest.BookingTicketVoucherId == bookingTicketVoucher.Id)"
      :key="bookingTicketVouchers.indexOf(bookingTicketVoucher)"
    )

</template>

<script>
import BookingTicketVoucher from "@/views/bookingTicketVouchers/BookingTicketVoucher.vue";
import { BookingTicketTypesEnum } from "@/lib/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";

export default {
    name: "BookingTicketVouchers",
    components: { BookingDateRequests, BookingTicketVoucher },
    props: {
        bookingTicket: {
            type: Object,
            required: true,
        },
        bookingTicketVouchers: {
            type: Array,
            required: true,
        },
        bookingDateRequests: {
            type: Array,
            required: false,
        },
        nights: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            BookingTicketTypesEnum: BookingTicketTypesEnum,
        };
    },
};
</script>

<style scoped></style>
