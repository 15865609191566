<template lang="pug">
  .booking-ticket-host
    .row
      .col-12
        font-awesome-icon.mr-2(:icon="['fas', 'hotel']" size="lg")
        span {{ host.Name }} {{ host.PORT }}
    .ml-5.mt-2
      .row
        .col-12
          h6.font-weight-bold Gutscheine
      booking-ticket-vouchers(
        :booking-ticket="bookingTicket"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :booking-date-requests="bookingDateRequests"
      )

</template>

<script>
import BookingTicketVouchers from "@/views/bookingTicketVouchers/BookingTicketVouchers.vue";
import BookingTicketVouchersComponent from "@/lib/components/bookingTicketVouchers/BookingTicketVouchersComponent";

export default {
    name: "BookingTicketHost",
    components: { BookingTicketVouchers },
    props: {
        bookingTicket: {
            type: Object,
            required: true,
        },
        host: {
            type: Object,
            required: true,
        },
        bookingDateRequests: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            bookingTicketVouchers: [],
        };
    },
    async mounted() {
        try {
            this.loadBookingTicketVouchers();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadBookingTicketVouchers() {
            try {
                const bookingTicketVouchersComponent =
                    new BookingTicketVouchersComponent();
                const bookingTicketVouchers =
                    await bookingTicketVouchersComponent.getBookingTicketVouchers(
                        this.$apollo,
                        this.bookingTicket?.Id,
                        [this.host.Id]
                    );
                if (!bookingTicketVouchers) {
                    return;
                }
                this.bookingTicketVouchers = bookingTicketVouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        "host.Id"() {
            try {
                this.loadBookingTicketVouchers();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
