const { Orders } = require("@/lib/graphql/orders/Orders.ts");

class OrdersComponent {
    async getOrder(apollo, id, voucherId) {
        try {
            if (!apollo || (!id && !voucherId)) {
                return undefined;
            }
            const order = await apollo
                .query({
                    query: Orders.Queries.Order,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                        voucherId: voucherId,
                    },
                })
                .then(({ data }) => data?.order)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return order;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getOrderByVoucherId(apollo, voucherId) {
        try {
            if (!apollo || !voucherId) {
                return undefined;
            }
            const order = await apollo
                .query({
                    query: Orders.Queries.OrderByVoucherId,
                    fetchPolicy: "no-cache",
                    variables: {
                        voucherId: voucherId,
                    },
                })
                .then(({ data }) => data?.orderByVoucherId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return order;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = OrdersComponent;
