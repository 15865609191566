<template lang="pug">
.booking-date-requests(v-if="bookingDateRequests && Array.isArray(bookingDateRequests) && bookingDateRequests.length")
  .row(v-for="bookingDateRequest in bookingDateRequests")
    .col-12
      booking-date-request(
        :booking-date-request="bookingDateRequest"
        :booking-ticket="bookingTicket"
        :booking-ticket-voucher="bookingTicketVoucher"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :show-booking-date-request-status="showBookingDateRequestStatus"
        :key="bookingDateRequests.indexOf(bookingDateRequest)"
      )

</template>

<script>
import BookingDateRequest from "@/views/bookingDateRequests/BookingDateRequest.vue";

export default {
    name: "BookingDateRequests",
    components: { BookingDateRequest },
    props: {
        bookingDateRequests: {
            type: Array,
            required: true,
        },
        bookingTicket: {
            type: Object,
            required: true,
        },
        bookingTicketVoucher: {
            type: Object,
            required: false,
        },
        bookingTicketVouchers: {
            type: Array,
            required: false,
        },
        showBookingDateRequestStatus: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            localBookingDateRequests: this.bookingDateRequests,
        };
    },
    watch: {
        bookingDateRequests() {
            try {
                this.localBookingDateRequests = this.bookingDateRequests;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
