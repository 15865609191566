import gql from "graphql-tag";

export const Items = {
    Queries: {
        Items: gql`
        query items($from: Date, $to: Date, $productId: ID, $hostId: ID, $orderId: ID, $newsletterId: ID, $platformIds: [ID], $referrerIds: [ID], $transactionTypeIds: [ID], $shippingTypeIds: [ID], $orderStatusCodes: [ID], $advertisingTypeIds: [ID], $voucherStatusCodes: [ID]) {
            items(from: $from, to: $to, productId: $productId, hostId: $hostId, orderId: $orderId, newsletterId: $newsletterId, platformIds: $platformIds, referrerIds: $referrerIds, transactionTypeIds: $transactionTypeIds, shippingTypeIds: $shippingTypeIds, orderStatusCodes: $orderStatusCodes, advertisingTypeIds: $advertisingTypeIds, voucherStatusCodes: $voucherStatusCodes) {
                Id
                Guid
                OrderId
                ItemId
                OrderRowId
                ExternalItemId
                ItemNo
                ProductId
                Description
                Quantity
                SingleItemPriceGross
                TaxRate
                InsuranceAmount
                ItemTypeId
                SingleIncludedShippingCosts
                IsDealSale
                Order {
                    OrderDate
                }
            }
        }`,
        ItemsValue: gql`
        query itemsValue($from: Date, $to: Date, $productId: ID, $hostId: ID, $orderId: ID, $newsletterId: ID, $platformIds: [ID], $referrerIds: [ID], $transactionTypeIds: [ID], $shippingTypeIds: [ID], $orderStatusCodes: [ID], $advertisingTypeIds: [ID], $voucherStatusCodes: [ID]) {
            itemsValue(from: $from, to: $to, productId: $productId, hostId: $hostId, orderId: $orderId, newsletterId: $newsletterId, platformIds: $platformIds, referrerIds: $referrerIds, transactionTypeIds: $transactionTypeIds, shippingTypeIds: $shippingTypeIds, orderStatusCodes: $orderStatusCodes, advertisingTypeIds: $advertisingTypeIds, voucherStatusCodes: $voucherStatusCodes)
        }`,
        ItemByVoucherId: gql`
        query itemByVoucherId($voucherId: ID) {
            itemByVoucherId(voucherId: $voucherId) {
                Id
                Guid
                OrderId
                ItemId
                OrderRowId
                ExternalItemId
                ItemNo
                ProductId
                Description
                Quantity
                SingleItemPriceGross
                TaxRate
                InsuranceAmount
                ItemTypeId
                SingleIncludedShippingCosts
                IsDealSale
            }
        }`,
        Item: gql`
        query item($id: ID, $voucherId: ID) {
            item(id: $id, voucherId: $voucherId) {
                Id
                Guid
                OrderId
                ItemId
                OrderRowId
                ExternalItemId
                ItemNo
                ProductId
                Description
                Quantity
                SingleItemPriceGross
                TaxRate
                InsuranceAmount
                ItemTypeId
                SingleIncludedShippingCosts
                IsDealSale
            }
        }`,
    },
};
